<template>
  <div>
    <div v-if="model === false" class="layer1">
      <div class="leftLayer" @click="back"></div>

      <div class="rightLayer">
        <div class="communicitynet" @click="English"><div class="net"></div> &nbsp;&nbsp;English</div>

        <div class="communicity" @click="gpts">GPT Community</div>

        <div class="communicity" @click="show">Join Now</div>

        <div v-if="userId" class="loginUser">
          <div style="display: flex">
            <div class="communicitynet" @click="English"><div class="net"></div> &nbsp;&nbsp;English</div>
            <el-dropdown class="userInfo">
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <el-avatar :size="40" :src="avator" class="avator"></el-avatar>
                <div class="userName">
                  Hi!<br/>
                  <span></span>
                  {{ userName }}
                </div>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  style="display: flex; height:32px;text-align: left;font-size: 12px;font-family: arial, sans-serif, 'Microsoft Yahei'"
                                   class="hoverItem">
                  <router-link style="display: flex" class="flex" to="/infoMobile">
                    <div>
                      <img
                          alt=""
                          class="icon not"
                          style="width: 20px; height: 20px; margin: 7px 5px auto 0"
                          src="@/assets/userIcon.png"
                      />
                    </div>
                    <span style="margin-top: 0">Personal Center</span>
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item
                    style="display: flex; height:32px;text-align: left;font-size:12px;;font-family: arial, sans-serif, 'Microsoft Yahei'"
                    @click.native="outLogin()"
                >
                  <img loading="lazy"
                      alt=""
                      class="icon hover"
                      src="@/assets/outHoverIcon.png"
                      style="width: 20px; height: 20px; margin: auto 5px auto 0"
                  />
                  Login out
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
<!--        <div v-else class="login" @click="login">Login/Register</div>-->
      </div>
    </div>
    <div v-else>
      <div v-if="showsearch" class="searchnew">
        <div style="float: right;margin: 5px"><i class="el-icon-circle-close" style="font-size: 20px;font-weight:bold;color: #163eee"
                                                 @click="showsearch=false"></i></div>
        <div class="search">
          <el-input v-model="keyWords" style="border: none" placeholder="Please enter the GPT or content keyword you are looking for" @input="getSearch"></el-input>

          <i class="el-icon-search"
             style="margin-right: 15px;z-index: 999999999;font-size: 16px;color:gray;font-weight: bold;height: 45px;display: flex;align-items: center;justify-content: center"></i>
        </div>

        <div v-if="search.length>0" class="searchBack">
          <li v-for="item in search" :key="item">
            <div class="searchContent" @click="routerSkip(item.link)">
              <div class="title1" v-html="item.title"></div>
              <div class="title2" v-html="item.contend"></div>
            </div>

          </li>

        </div>
        <div v-else class="searchBackno">
          The information you are looking for about <span style="color: #eb7c31;margin: 0 0.5vw">“{{ keyWords }}”</span>has not been retrieved yet, <br>Try changing the keywords！
        </div>
      </div>
      <div class="layerModel">
<!--        <div></div>-->
        <div class="leftLayer" @click="back"></div>
        <div class="rightLayer">
          <div style="display: flex">
            <div class="login" style="margin-right: -10px" @click="showsearch=true"><i style="color: black" class="el-icon-search"/></div>
            <div class="communicitynet" style="margin-top: -1px;margin-right: -5px;color: black">
              <el-select v-model="value" placeholder="Select" @change="English">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-dropdown>
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <img :src="three" class="communicitynet"
                     style="height: 23px;width: 20px;border-radius:0;display: flex;justify-content: center;align-items: center">
              </div>

              <el-dropdown-menu slot="dropdown" style="text-align: right">

                <el-dropdown-item
                    class="hoverItem"
                    style="display: flex; height:32px;text-align: left;font-size: 12px;font-family: arial, sans-serif, 'Microsoft Yahei'">
                  <div @click="show">
                    <span style="margin-top: 0">Join Now</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 12px;height: 32px"
                >
                  <div @click="gpts">GPT Community</div>


                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
<!--      <video id="myVideo" ref="myVideo" width="100%" height="100%" style="object-fit: cover;position:absolute;top:0;left:0; z-index: -1" autoplay loop muted playsinline>-->
<!--        <source src="@/video/bannerv3.mp4" type="video/mp4">-->
<!--      </video>-->
      <div class="layer2">
        <div class="title1" >Geological Engineering GPTs</div>
        <div class="title2" >An artificial intelligence cloud platform that links the job site, technicians, and GPTs in real time</div>

        <div class="title3"  >
          <div style="display: flex;" @click="position1">
            <div style="height: 14px;width: 14px;margin: 0 5px"><img loading="lazy" src="@/assets/biaoti5.png"></div>
            GEGPTs <div class="iconstyle">
            <i style="color: white;display: flex;align-items: center;margin-left: 10px;font-weight: bold"  class="el-icon-arrow-right"></i>
          </div>
          </div>
          <div style="display: flex;margin-top: 10px"   @click="position2">
            <div style="height: 14px;width: 14px;margin: 0 5px"><img loading="lazy" src="@/assets/biaoti2.png"></div>
            Global Online Expert  Support Services <div class="iconstyle">
            <i style="color: white;display: flex;align-items: center;margin-left: 10px;font-weight: bold"  class="el-icon-arrow-right"></i>
          </div>
          </div>
          <div style="display: flex;margin-top: 10px" @click="position3">
            <div style="height: 14px;width: 14px;margin: 0 5px"><img loading="lazy" src="@/assets/biaoti9.png"></div>
            Oil & Gas Field Development  Series Technical Services <div class="iconstyle">
            <i style="color: white;display: flex;align-items: center;margin-left: 10px;font-weight: bold"  class="el-icon-arrow-right"></i>
          </div>
          </div>
        </div>
        <div slot="reference" class="search"  >
          <div style=" display: flex;">
          <i class="el-icon-search"
             style="margin-left: 10px;font-size: 14px;color:gray;font-weight: bold;height:45px;display: flex;align-items: center"></i>
          <el-input v-model="keyTitle" style="border: none" placeholder="Please enter your question" @focus="showsearch=true"></el-input></div>
          <div class="down">
            <img src="@/assets/down.png">
          </div>
        </div>

      </div>

      <!--      <div class="layer3">-->
      <!--        <div class="communicityGpt" @click="gpts">GPT Community</div>-->
      <!--        <div class="communicity" @click="show">Join Now</div>-->
      <!--      </div>-->
    </div>
    <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
  </div>
</template>


<script>
import {
  logOut,
  userDetail,
  getAuthorize,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
import Fingerprint2 from "fingerprintjs2";

export default {
  name: "headtip",
  props: [],
  components: { },
  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      showsearch: false,
      model: false,
      keyTitle:'',
      userName: "",
      avator: require("@/assets/user.jpg"),
      userId: localStorage.getItem("userId"),
      dialogconsult: false,
      userInfo:"",
      keyWords: "",
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      three: require("@/assets/three.png"),
      value: '选项1',
      options: [{
        value: '选项1',
        label: 'EN'
      }, {
        value: '选项2',
        label: '中文'
      },],
    };
  },
  created() {
    this.getSearch()
    console.log("userId", this.userId);

  },
  watch: {
    "$store.state.userId"(newValue, oilValue) {
      this.userId = newValue;
      if (newValue && newValue != oilValue) {
        this.getUser();
        this.ifComplete()
      }
    },
  },
  mounted() {
    const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true;
    } else {
      this.model = false;
    }
    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        pixelRatio: true,
      },
    };
    console.log(options, "options");
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      that.isLogin();
    });
    this.userId = localStorage.getItem("userId");
    if (this.userId) {
      this.getUser();
      this.ifComplete()
    }
  },
  methods: {
    getSearch() {
      console.log( this.search.length)
      this.$axios
          .get("api/search/getThirdByName_IntroduceEn?keyword=" + this.keyWords, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              console.log(res.data.data.keywordList[0]!=="")
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }


            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      this.$axios
          .post("https://www.oilgasinfoai.com/auth/oauth/getLoginStatus", data)
          .then((res) => {
            console.log(res, "res11111");
            let options = this.$route.query;
            if (res.data.data.isLogin == true && this.$store.state.access_token) {
              this.getUser();
              this.ifComplete()
            } else if (
                res.data.data.isLogin == true &&
                !this.$store.state.access_token &&
                !options.code
            ) {
              this.login();
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 2
            ) {
              console.log("isLogin Flase type 2");
              //this.outLogin();
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.userId = "";
              this.userName = "";
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 1
            ) {
              console.log("isLogin Flase type 1");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.userId = "";
              this.userName = "";
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.login();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getUser() {
      userDetail(this.userId).then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        localStorage.setItem("userId", res.data.gptsUserId);
        this.$store.state.user = res.data;
        this.$store.state.userId = res.data.gptsUserId;
        this.userId = res.data.gptsUserId;
        this.userName = this.userInfo.gptsUserName;
        this.avator=this.baseURL+this.userInfo.avatar
      });
    },
    ifComplete(){
      this.$axios
          .get("/info/gpts_user/judgment_info/" + this.userId)
          .then((res) => {
            if(res.data.msg=="信息已完善"){
              localStorage.setItem("infoComplete",1)
            }
            else{
              localStorage.setItem("infoComplete",0)
            }

            console.log("--------ren",res.data)

          })
          .catch((err) => {
            console.log(err);
          });

    },
    outLogin() {
      // localStorage.removeItem("userId");
      // window.location.reload();
      logOut()
          .then((res) => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            // deleteUserAgent(localStorage.getItem("murmur"))
            let data = {
              userAgent: localStorage.getItem("murmur"),
            };
            this.$axios
                .post(
                    "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
                    data
                )
                .then(() => {
                  localStorage.removeItem("murmur");
                  window.location.href = res.data;
                });
          })
          .catch((error) => {
            console.error("Login out失败:", error);
            this.$message.error("Login out失败，请重试"); // 提示用户Login out失败
            // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
          });
    },
    login() {
      // this.$router.push("/login")
      // let href = "https://www.oilgasgpts.com/";
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
    },
    position1(){
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogTop",1);
      } else {
        sessionStorage.setItem("ground", 1)
        window.open("/", "_blank");
      }

    },
    position2(){
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogTop",3);
      } else {
        sessionStorage.setItem("ground", 3)
        window.open("/", "_blank");
      }
    },
    position3(){
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogTop",2);
      } else {
        sessionStorage.setItem("ground", 2)
        window.open("/", "_blank");
      }
    },

    position4(){
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogTop",4);
      } else {
        sessionStorage.setItem("ground", 4)
        window.open("/", "_blank");
      }
    },

    position5(){
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogTop",6);
      } else {
        sessionStorage.setItem("ground", 6)
        window.open("/", "_blank");
      }
    },

    back() {
      window.open("https://www.ogdri.com/", "_blank");
    },
    English(){
      const routeName = this.$route.name;
      console.log(routeName)
      if(this.value==="选项1")
      {
        if (routeName === "Home") {
          window.open("https://www.ogdri.com/", "_blank");
        }  else {
          window.open("https://www.ogdri.com/" + routeName, "_blank");
        }

      }
      else{
        if(routeName==="Home"){
          window.open("https://cn.ogdri.com/", "_blank");
        }
        else{
          window.open("https://cn.ogdri.com/"+routeName, "_blank");
        }

      }

    },
    gpts() {
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    show() {
      this.$emit("showDialog");
    },
    hide() {
      this.dialogconsult = false;
    },
  },
};
</script>

<style>
.el-popper[x-placement^=bottom] .popper__arrow {
  left:80% !important;

  border-bottom-color: rgb(250, 250, 250) !important;
}
</style>
<style lang="scss" scoped type="text/scss">
.avator {
  border: 2px solid #fff;
}

.userName {
  color: #2f6eff;
  font-size: 1vw;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layer1 {
  display: flex;
  justify-content: space-between;
  padding: 0 1.61756210283073vw;

  .leftLayer {
    cursor: pointer;
    width: 10.2790294627383vw;
    height:4.54993819530284vh;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicitynet {

      cursor: pointer;
      font-size: 1vw;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      background-color:  rgba(255, 255, 255, 0.5);
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
      display: flex;
      .net{
        background-image: url("../assets/net.png");
        background-size:contain;
        background-position:center;
        background-repeat:no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 20px;
        height: 20px;
      }
    }
    //.communicitynet:hover {
    //  background: rgba(255, 255, 255);
    //}
    .communicity {

      cursor: pointer;
      font-size: 1vw;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2f6eff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: #2f6eff 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    //.communicity:hover {
    //  background: rgba(255, 255, 255, 0.5);
    //}

    .loginUser {

      //border: #2F6EFF 1px solid;
      border-radius: 53px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: Roboto,sans-serif;
      color: #2f6eff;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        //.icon.hover {
        //  display: none;
        //}
      }
    }

    .login {

      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: Roboto,sans-serif;
      font-weight: 400;
      color: #2f6eff;
      line-height: 3.33745364647713vh;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      background: white;
      border: #2f6eff 1px solid;
    }

    //.login:hover {
    //  color: #2f6eff;
    //  background: rgba(255, 255, 255, 0.5);
    //  border: #2f6eff 1px solid;
    //}
  }
}
.searchnew {
  background-color: #d7d7d7;
  position: absolute;
  width: 100vw;
  top:0;
  left:0;
  height: fit-content;
  z-index: 999999999;

  .searchBack {
    //background-color: rgba(102, 102, 102, 0.2);
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    padding: 0 15px;
    row-gap: 15px;
    list-style-type: none;
  }

  .searchBackno {
    padding: 15px 25px;
    font-weight: bold;
    text-align: center;
  }

  .searchContent {
    cursor: pointer;
    //background: #e5ebfa;
    border: white 1px solid;
    background-color: #f2f2f2;
    //box-shadow: 5px 0 14px 0px #e5ebfa;
    padding: 10px;
    height: 100%;
    border-radius: 9px;
    //color: white;

    .title1 {
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
    }

    .title2 {
      font-size: 12px;
      margin-top: 5px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
    }
  }

  .search {
    margin: 35px 5vw 25px 5vw;
    display: flex;
    border-radius: 9px;
    width: 90vw;
    background-color: white;

    ::v-deep .el-input {
      border-radius: 9px;
      width: 80vw;

      font-size: 14px;
    }

    ::v-deep .el-input__inner {
      border-radius: 9px;
      height: 45px !important;
      border: white 1px solid !important;
      background-color: transparent !important;
    }

  }
}
.layerModel {
  display: flex;
  justify-content: space-between;
  //background-color: white;
  padding: 15px 5px 10px 5px;

  .userName {
    color: black;
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
    margin-left: 10px;
    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .leftLayer {
    cursor: pointer;
    width: 100px;
    height: 20px;
    margin:-3px 0 0 5px;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    margin-top: -0.89443757725587vh;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicity {

      cursor: pointer;
      font-size: 14px;
       font-family: Roboto,sans-serif;
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    //.communicity:hover {
    //  background: rgba(255, 255, 255, 0.5);
    //}
    .communicitynet {

      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
       font-family: Roboto,sans-serif;
      font-weight: 400;
      color: white;
      line-height: 15px;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 5px;
      height: 35px;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 75px;
        font-size: 14px;
        color: black;
      }
      ::v-deep .el-select .el-input .el-select__caret{
        color: black;
      }

      ::v-deep .el-input__inner {
        border-radius: 9px;
        color: black;
        height: 5vh !important;
        border: none !important;
        background-color: transparent !important;
      }

      .net {
        background-image: url("../assets/netModel.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 14px;
        height: 14px;
      }
    }

    //.communicitynet:hover {
    //  color: #2f6eff;
    //  background: rgba(255, 255, 255, 0.5);
    //  border: #2f6eff 1px solid;
    //}

    .loginUser {

      //border: #2F6EFF 1px solid;
      border-radius: 53px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
       font-family: Roboto,sans-serif;
      color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        //.icon.hover {
        //  display: none;
        //}
      }
    }

    .login {

      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
       font-family: Roboto,sans-serif;
      color: #ffffff;
      line-height: 15px;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 10px;
      height: 35px;
      font-weight: bold;
      //background: white;
      //border: #2f6eff 1px solid;
    }

    //.login:hover {
    //  color: #2f6eff;
    //  background: rgba(255, 255, 255, 0.5);
    //  border: #2f6eff 1px solid;
    //}
  }
}
.layer2 {
  position: relative;
  height: 85vh;
  .title1 {
    margin:3vh auto 0 auto;
    width: fit-content;
    height:23px;
    font-size: 23px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: white;
    line-height: 23px;
  }
  .title2 {
    padding:3vh 15px 0 15px;
    text-align: center;
    width: fit-content;
    height:fit-content;
    font-size: 14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: white;
    line-height: 25px;
  }
  .title3 {
    //display: flex;
    //text-align: center;
    position: absolute;
    bottom:85px;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    height: fit-content;
    font-size:14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: white;
    line-height:25px;
    .iconstyle{
      display: flex;align-items: center;
      animation: pulse 2s infinite;
    }
    @keyframes pulse { 0% { transform: translateX(0px); /* 原始大小 */ } 50% { transform: translateX(5px); /* 放大到120% */ } 100% { transform: translateX(-5px); /* 恢复到原始大小 */ } }

  }
  .search {
    position: absolute;
    bottom:25px;
    left:50%;
    transform: translateX(-50%);
    border-radius: 9px;
    width: 80%;
    height: 45px;
    background-color: white;
    .down{
      margin:10px auto;
      height: 35px;
      width: 35px;
      animation: up 2s infinite;
    }
    @keyframes up { 0% { transform: translateY(0px) ;opacity: 0.5 /* 原始大小 */ } 50% { transform: translateY(5px);opacity: 1 /* 放大到120% */ } 100% { transform: translateY(-5px);opacity: 0 /* 恢复到原始大小 */ } }

    ::v-deep .el-input {
      border-radius: 9px;
      height:45px;
      width: 80%;
      font-size: 14px;
    }
    ::v-deep .el-input__inner {
      border-radius: 9px;
      height:45px !important;
      border: white 1px solid !important;
      //background-color: transparent !important;
      background-color: transparent !important;
    }

  }

}
.layer3 {
  position: absolute;
  display: flex;
  bottom:20px;
  opacity: 1;
  width: 90%;
  justify-content: center;
  .communicity {

    cursor: pointer;
    font-size: 16px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    color: #2f6eff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 53px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }
  .communicityGpt {

    cursor: pointer;
    font-size: 16px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    color:white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 53px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    background-color:#2f6eff ;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }

}
</style>
